import { applyReducers } from '@m/effector-utils';
import {
  LocationSubscriptionPlan,
  LocationSubscriptionPlanForm,
  Location,
  SiteEquipment,
  WhitelistedPhoneView,
  RateSchedule,
  WhitelistedEmailView,
  AdminLocationJoinQR,
  LocationValidationV2,
  Validation,
  TransactionFeeDetail,
  TransactionFeePlan,
  SubscriptionFeeDetail,
  SubscriptionFeePlan,
  LocationLane,
  LocationLaneDetail,
  LocationGateActions,
  BigMacDevice,
  ParkingPassPlan,
} from '@m/types/api';
import { createStore, Effect, Event } from 'effector';

import {
  createLocation,
  deleteLocation,
  getLocations,
  getLocationDetail,
  updateLocation,
  getLocationJoinQrCode,
  getLocationEquipmentList,
  updatePlatformSettings,
  getUnattendedStatusFx,
  setUnattendedStatusFx,
  getEquipmentlessStatusFx,
  setEquipmentlessStatusFx,
  getSubscriptionPlanById,
  getLocationSubscriptionPlans,
  createLocationSubscriptionPlan,
  updateSubscriptionPlan,
  deleteSubscriptionPlan,
  getWhitelistedPhones,
  addWhitelistedPhones,
  deleteWhitelistedPhone,
  fetchSiteRateSchedules,
  addWhitelistedEmails,
  deleteWhitelistedEmail,
  getWhitelistedEmails,
  getLocationValidations,
  getValidationDetail,
  getTransactionFeePlans,
  getSubscriptionFeePlans,
  getTransactionFeesForSite,
  getSubscriptionFeesForSite,
  getLocationLanes,
  getLocationDevices,
  getLocationLaneDetail,
  getLaneGateDetail,
  updateLocationHours,
  removeLaneGate,
  createSiteParkingPassPlan,
  deleteSiteParkingPassPlan,
  getSiteParkingPassPlans,
  updateSiteParkingPassPlan,
} from './actions';

import type { DoneHandler } from '@m/effector-utils';
import type { APIResponse } from '@m/utils/http';

export type State = {
  locations: Location[];
  locationDetails: { [id: string]: Location };
  locationEquipmentMap: { [id: string]: SiteEquipment[] };
  siteRateSchedules: { [siteId: number]: RateSchedule | null };
  siteParkingPassPlans: { [zoneUuid: string]: ParkingPassPlan[] };
  subscriptionPlanForm: LocationSubscriptionPlanForm | null;
  siteSubscriptionPlanViewMap: { [siteId: number]: LocationSubscriptionPlan[] };
  subscriptionPlanWhitelistedPhoneMap: { [subPlanId: number]: WhitelistedPhoneView[] };
  subscriptionPlanWhitelistedEmailMap: { [subPlanId: number]: WhitelistedEmailView[] };
  joinQrMap: { [id: number]: AdminLocationJoinQR };
  unattendedStatusMap: { [id: number]: boolean };
  equipmentlessStatusMap: { [id: number]: boolean };
  validationListMap: { [id: number]: LocationValidationV2[] };
  validationPlanMap: { [id: number]: Validation };
  transactionFeePlans: TransactionFeeDetail[];
  subscriptionFeePlans: SubscriptionFeeDetail[];
  siteTransactionFeesMap: { [id: number]: TransactionFeePlan[] };
  siteSubscriptionFeesMap: { [id: number]: SubscriptionFeePlan[] };
  locationLanesMap: { [id: number]: LocationLane[] };
  locationDeviceMap: { [id: number]: BigMacDevice[] };
  locationGateActionsMap: { [id: number]: LocationGateActions };
  laneDetailMap: { [id: number]: LocationLaneDetail };
};

export const initialState: State = {
  locations: [],
  locationDetails: {},
  locationEquipmentMap: {},
  siteRateSchedules: {},
  siteParkingPassPlans: {},
  subscriptionPlanForm: null,
  siteSubscriptionPlanViewMap: {},
  subscriptionPlanWhitelistedPhoneMap: {},
  subscriptionPlanWhitelistedEmailMap: {},
  joinQrMap: {},
  equipmentlessStatusMap: {},
  unattendedStatusMap: {},
  validationListMap: {},
  validationPlanMap: {},
  transactionFeePlans: [],
  subscriptionFeePlans: [],
  siteTransactionFeesMap: {},
  siteSubscriptionFeesMap: {},
  locationLanesMap: {},
  locationDeviceMap: {},
  locationGateActionsMap: {},
  laneDetailMap: {},
};

const store = createStore(initialState);

type Reducers = {
  createLocation: {
    action: Effect<Parameters<typeof createLocation>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof createLocation>[0], State>;
  };
  deleteLocation: {
    action: Effect<Parameters<typeof deleteLocation>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof deleteLocation>[0], State>;
  };
  getLocations: {
    action: Effect<Parameters<typeof getLocations>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocations>[0], State>;
  };
  getLocationDetail: {
    action: Effect<Parameters<typeof getLocationDetail>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocationDetail>[0], State>;
  };
  updateLocation: {
    action: Effect<Parameters<typeof updateLocation>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateLocation>[0], State>;
  };
  updateLocationHours: {
    action: Effect<Parameters<typeof updateLocationHours>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateLocationHours>[0], State>;
  };
  getLocationJoinQrCode: {
    action: Effect<Parameters<typeof getLocationJoinQrCode>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocationJoinQrCode>[0], State>;
  };
  getLocationEquipmentList: {
    action: Effect<Parameters<typeof getLocationEquipmentList>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocationEquipmentList>[0], State>;
  };

  // Platform settings
  updatePlatformSettings: {
    action: Effect<Parameters<typeof updatePlatformSettings>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updatePlatformSettings>[0], State>;
  };
  getUnattendedStatusFx: {
    action: Effect<Parameters<typeof getUnattendedStatusFx>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getUnattendedStatusFx>[0], State>;
  };
  setUnattendedStatusFx: {
    action: Effect<Parameters<typeof setUnattendedStatusFx>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof setUnattendedStatusFx>[0], State>;
  };
  getEquipmentlessStatusFx: {
    action: Effect<Parameters<typeof getEquipmentlessStatusFx>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getEquipmentlessStatusFx>[0], State>;
  };
  setEquipmentlessStatusFx: {
    action: Effect<Parameters<typeof setEquipmentlessStatusFx>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof setEquipmentlessStatusFx>[0], State>;
  };
  // Rates
  fetchSiteRateSchedules: {
    action: Effect<Parameters<typeof fetchSiteRateSchedules>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchSiteRateSchedules>[0], State>;
  };

  // Parking passes
  getParkingPassPlans: {
    action: Effect<Parameters<typeof getSiteParkingPassPlans>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSiteParkingPassPlans>[0], State>;
  };
  createParkingPassPlan: {
    action: Effect<Parameters<typeof createSiteParkingPassPlan>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof createSiteParkingPassPlan>[0], State>;
  };
  updateParkingPassPlan: {
    action: Effect<Parameters<typeof updateSiteParkingPassPlan>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateSiteParkingPassPlan>[0], State>;
  };
  deleteParkingPassPlan: {
    action: Effect<Parameters<typeof deleteSiteParkingPassPlan>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof deleteSiteParkingPassPlan>[0], State>;
  };

  // Subscription Plan
  getSubscriptionPlanById: {
    action: Effect<Parameters<typeof getSubscriptionPlanById>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSubscriptionPlanById>[0], State>;
  };
  getLocationSubscriptionPlans: {
    action: Effect<Parameters<typeof getLocationSubscriptionPlans>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocationSubscriptionPlans>[0], State>;
  };
  createLocationSubscriptionPlan: {
    action: Effect<Parameters<typeof createLocationSubscriptionPlan>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof createLocationSubscriptionPlan>[0], State>;
  };
  updateSubscriptionPlan: {
    action: Effect<Parameters<typeof updateSubscriptionPlan>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateSubscriptionPlan>[0], State>;
  };
  deleteSubscriptionPlan: {
    action: Effect<Parameters<typeof deleteSubscriptionPlan>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof deleteSubscriptionPlan>[0], State>;
  };
  // Whitelisted Phones
  getWhitelistedPhones: {
    action: Effect<Parameters<typeof getWhitelistedPhones>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getWhitelistedPhones>[0], State>;
  };
  addWhitelistedPhones: {
    action: Effect<Parameters<typeof addWhitelistedPhones>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof addWhitelistedPhones>[0], State>;
  };
  deleteWhitelistedPhone: {
    action: Effect<Parameters<typeof deleteWhitelistedPhone>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof deleteWhitelistedPhone>[0], State>;
  };
  // Whitelisted Emails
  getWhitelistedEmails: {
    action: Effect<Parameters<typeof getWhitelistedEmails>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getWhitelistedEmails>[0], State>;
  };
  addWhitelistedEmails: {
    action: Effect<Parameters<typeof addWhitelistedEmails>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof addWhitelistedEmails>[0], State>;
  };
  deleteWhitelistedEmail: {
    action: Effect<Parameters<typeof deleteWhitelistedEmail>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof deleteWhitelistedEmail>[0], State>;
  };

  // ValidationsEnum
  getLocationValidations: {
    action: Effect<Parameters<typeof getLocationValidations>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocationValidations>[0], State>;
  };
  getValidationDetail: {
    action: Effect<Parameters<typeof getValidationDetail>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getValidationDetail>[0], State>;
  };

  // #region Fees
  getTransactionFeePlans: {
    action: Effect<Parameters<typeof getTransactionFeePlans>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getTransactionFeePlans>[0], State>;
  };
  getSubscriptionFeePlans: {
    action: Effect<Parameters<typeof getSubscriptionFeePlans>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSubscriptionFeePlans>[0], State>;
  };
  getTransactionFeesForSite: {
    action: Effect<Parameters<typeof getTransactionFeesForSite>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getTransactionFeesForSite>[0], State>;
  };
  getSubscriptionFeesForSite: {
    action: Effect<Parameters<typeof getSubscriptionFeesForSite>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSubscriptionFeesForSite>[0], State>;
  };
  // #endregion Fees

  // #region Lanes
  getLocationLanes: {
    action: Effect<Parameters<typeof getLocationLanes>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocationLanes>[0], State>;
  };
  getLocationLaneDetail: {
    action: Effect<Parameters<typeof getLocationLaneDetail>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocationLaneDetail>[0], State>;
  };
  getLocationDevices: {
    action: Effect<Parameters<typeof getLocationDevices>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocationDevices>[0], State>;
  };
  getLaneGateDetail: {
    action: Effect<Parameters<typeof getLaneGateDetail>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLaneGateDetail>[0], State>;
  };
  removeLaneGate: {
    action: Event<{ laneId: number }>;
    reducer: (state: State, payload: { laneId: number }) => State;
  };
  // #endregion Lanes
};

export const reducers: Reducers = {
  createLocation: {
    action: createLocation,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      // TODO
    }),
  },
  deleteLocation: {
    action: deleteLocation,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      // TODO
    }),
  },
  getLocations: {
    action: getLocations,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      locations: success ? data : state.locations,
    }),
  },
  getLocationDetail: {
    action: getLocationDetail,
    done: (state, { params: { id }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            locationDetails: {
              ...state.locationDetails,
              [id]: data,
            },
          }
        : state,
  },
  updateLocation: {
    action: updateLocation,
    done: (state, { params: { id }, result: { success, data } = {} }) => ({
      ...state,
      locationDetails: success
        ? {
            ...state.locationDetails,
            [id]: data,
          }
        : state.locationDetails,
    }),
  },
  updateLocationHours: {
    action: updateLocationHours,
    done: (state, { params: { id }, result: { success, data } = {} }) => ({
      ...state,
      locationDetails: success
        ? {
            ...state.locationDetails,
            [id]: data,
          }
        : state.locationDetails,
    }),
  },
  getLocationJoinQrCode: {
    action: getLocationJoinQrCode,
    done: (state, { params: { id }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            joinQrMap: {
              ...state.joinQrMap,
              [id]: data,
            },
          }
        : state,
  },
  updatePlatformSettings: {
    action: updatePlatformSettings,
    done: (state, { params: { id }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            locationDetails: {
              ...state.locationDetails,
              [id]: data,
            },
          }
        : state,
  },
  getUnattendedStatusFx: {
    action: getUnattendedStatusFx,
    done: (state, { params: { siteId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            unattendedStatusMap: {
              ...state.unattendedStatusMap,
              [siteId]: data.unattended,
            },
          }
        : state,
  },
  setUnattendedStatusFx: {
    action: setUnattendedStatusFx,
    done: (state, { params: { siteId, enabled }, result: { success } = {} }) =>
      success
        ? {
            ...state,
            unattendedStatusMap: {
              ...state.unattendedStatusMap,
              [siteId]: enabled,
            },
          }
        : state,
  },
  getEquipmentlessStatusFx: {
    action: getEquipmentlessStatusFx,
    done: (state, { params: { siteId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            equipmentlessStatusMap: {
              ...state.equipmentlessStatusMap,
              [siteId]: data.equipmentless,
            },
          }
        : state,
  },
  setEquipmentlessStatusFx: {
    action: setEquipmentlessStatusFx,
    done: (state, { params: { siteId, enabled }, result: { success } = {} }) =>
      success
        ? {
            ...state,
            equipmentlessStatusMap: {
              ...state.equipmentlessStatusMap,
              [siteId]: enabled,
            },
          }
        : state,
  },
  getLocationEquipmentList: {
    action: getLocationEquipmentList,
    done: (state, { params: { id }, result: { success, data } = {} }) => ({
      ...state,
      locationEquipmentMap: success
        ? {
            ...state.locationEquipmentMap,
            [id]: data,
          }
        : state.locationEquipmentMap,
    }),
  },
  fetchSiteRateSchedules: {
    action: fetchSiteRateSchedules,
    done: (state, { params: { siteId }, result: { success, data } = {} }) => {
      if (success) {
        return {
          ...state,
          siteRateSchedules: {
            [siteId]: data.rateSchedule,
          },
        };
      }
      return {
        ...state,
        siteRateSchedules: {
          [siteId]: null,
        },
      };
    },
  },
  getSubscriptionPlanById: {
    action: getSubscriptionPlanById,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      subscriptionPlanForm: success ? data : null,
    }),
  },
  getLocationSubscriptionPlans: {
    action: getLocationSubscriptionPlans,
    done: (state, { params: { siteId }, result: { success, data } = {} }) => ({
      ...state,
      siteSubscriptionPlanViewMap: success
        ? {
            ...state.siteSubscriptionPlanViewMap,
            [siteId]: data,
          }
        : state.siteSubscriptionPlanViewMap,
    }),
  },
  createLocationSubscriptionPlan: {
    action: createLocationSubscriptionPlan,
    done: (state, { params: { siteId, subscriptionPlan }, result: { success, data } = {} }) => {
      if (success) {
        return {
          ...state,
          siteSubscriptionPlan: {
            [siteId]: data,
          },
        };
      }
      return state;
    },
  },
  updateSubscriptionPlan: {
    action: updateSubscriptionPlan,
    done: (
      state,
      { params: { subscriptionPlanId, subscriptionPlan }, result: { success, data } = {} },
    ) => {
      if (success) {
        return {
          ...state,
          subscriptionPlanForm: data,
        };
      }
      return state;
    },
  },
  deleteSubscriptionPlan: {
    action: deleteSubscriptionPlan,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      deleted: data.deleted,
    }),
  },
  getWhitelistedPhones: {
    action: getWhitelistedPhones,
    done: (state, { params: { subscriptionPlanId }, result: { success, data } = {} }) => {
      if (success) {
        return {
          ...state,
          subscriptionPlanWhitelistedPhoneMap: {
            [subscriptionPlanId]: data,
          },
        };
      }
      return state;
    },
  },
  addWhitelistedPhones: {
    action: addWhitelistedPhones,
    done: (
      state,
      { params: { subscriptionPlanId, whitelistedPhoneForm }, result: { success, data } = {} },
    ) => {
      const newPhones = whitelistedPhoneForm.phoneNumbers;
      if (success && newPhones.length > 0) {
        return {
          ...state,
          subscriptionPlanWhitelistedPhoneMap: {
            [subscriptionPlanId]: [
              ...newPhones.map(
                (number) =>
                  ({
                    id: 0,
                    phoneNumber: number,
                    addedAt: new Date().getTime(),
                  }) as WhitelistedPhoneView,
              ),
              ...state.subscriptionPlanWhitelistedPhoneMap[subscriptionPlanId],
            ],
          },
        };
      }
      return state;
    },
  },
  deleteWhitelistedPhone: {
    action: deleteWhitelistedPhone,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      deleted: data.deleted,
    }),
  },
  getWhitelistedEmails: {
    action: getWhitelistedEmails,
    done: (state, { params: { subscriptionPlanId }, result: { success, data } = {} }) => {
      if (success) {
        return {
          ...state,
          subscriptionPlanWhitelistedEmailMap: {
            [subscriptionPlanId]: data,
          },
        };
      }
      return state;
    },
  },
  addWhitelistedEmails: {
    action: addWhitelistedEmails,
    done: (
      state,
      { params: { subscriptionPlanId, whitelistedEmailForm }, result: { success, data } = {} },
    ) => {
      const newEmails = whitelistedEmailForm.emails;
      if (success) {
        return {
          ...state,
          subscriptionPlanWhitelistedEmailMap: {
            [subscriptionPlanId]: [
              ...newEmails.map(
                (email) =>
                  ({
                    id: 0,
                    email,
                    addedAt: new Date().getTime(),
                  }) as WhitelistedEmailView,
              ),
              ...state.subscriptionPlanWhitelistedEmailMap[subscriptionPlanId],
            ],
          },
        };
      }
      return state;
    },
  },
  deleteWhitelistedEmail: {
    action: deleteWhitelistedEmail,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      deleted: data.deleted,
    }),
  },

  getLocationValidations: {
    action: getLocationValidations,
    done: (state, { params: { id }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            validationListMap: {
              ...state.validationListMap,
              [id]: data,
            },
          }
        : state,
  },
  getValidationDetail: {
    action: getValidationDetail,
    done: (state, { params: { validationId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            validationPlanMap: {
              ...state.validationPlanMap,
              [validationId]: data.validationPlan,
            },
          }
        : state,
  },

  // #region Fees Reducers
  getTransactionFeePlans: {
    action: getTransactionFeePlans,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            transactionFeePlans: data,
          }
        : state,
  },
  getSubscriptionFeePlans: {
    action: getSubscriptionFeePlans,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            subscriptionFeePlans: data,
          }
        : state,
  },
  getTransactionFeesForSite: {
    action: getTransactionFeesForSite,
    done: (state, { params: { siteId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            siteTransactionFeesMap: {
              ...state.siteTransactionFeesMap,
              [siteId]: data,
            },
          }
        : state,
  },
  getSubscriptionFeesForSite: {
    action: getSubscriptionFeesForSite,
    done: (state, { params: { siteId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            siteSubscriptionFeesMap: {
              ...state.siteSubscriptionFeesMap,
              [siteId]: data,
            },
          }
        : state,
  },
  // #endregion Fees Reducers

  // #region Lanes Reducers
  getLocationLanes: {
    action: getLocationLanes,
    done: (state, { params: { locationId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            locationLanesMap: {
              ...state.locationLanesMap,
              [locationId]: data.lanes,
            },
            locationGateActionsMap: {
              ...state.locationGateActionsMap,
              [locationId]: data.attributes,
            },
          }
        : state,
  },
  getLocationLaneDetail: {
    action: getLocationLaneDetail,
    done: (state, { params: { laneId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            laneDetailMap: {
              ...state.laneDetailMap,
              [laneId]: {
                ...state.laneDetailMap[laneId],
                laneDetails: data.laneDetails,
                equipmentDetails: data.equipmentDetails ?? null,
                gateDetails: data.gateDetails ?? null,
                signCode: data.signCode ?? null,
              },
            },
          }
        : state,
  },
  getLocationDevices: {
    action: getLocationDevices,
    done: (state, { params: { locationId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            locationDeviceMap: {
              ...state.locationDeviceMap,
              [locationId]: data,
            },
          }
        : state,
  },
  getLaneGateDetail: {
    action: getLaneGateDetail,
    done: (state, { params: { laneId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            laneDetailMap: {
              ...state.laneDetailMap,
              [laneId]: {
                ...state.laneDetailMap[laneId],
                gateActions: data.gateActions ?? null,
                gateStatus: data.gateLaneView ?? null,
                edgeDevice: data.edgeGateView ?? null,
              },
            },
          }
        : state,
  },
  removeLaneGate: {
    action: removeLaneGate,
    reducer: (state, { laneId }) => {
      const next = { ...state };
      const lane = next.laneDetailMap[laneId];
      delete lane.edgeDevice;
      delete lane.gateActions;
      delete lane.gateStatus;
      return next;
    },
  },
  // #endregion Lanes Reducers
  // #region Parking Pass Plans
  getParkingPassPlans: {
    action: getSiteParkingPassPlans,
    done: (state, { params: { zoneUuid }, result: { success, data } = {} }) => ({
      ...state,
      siteParkingPassPlans: success
        ? {
            ...state.siteParkingPassPlans,
            [zoneUuid]: data.parkingPassPlans,
          }
        : {
            ...state.siteParkingPassPlans,
          },
    }),
  },
  createParkingPassPlan: {
    action: createSiteParkingPassPlan,
    done: (state, { params: { zoneUuid }, result: { success, data } = {} }) => ({
      ...state,
      siteParkingPassPlans: success
        ? {
            ...state.siteParkingPassPlans,
            [zoneUuid]: [...(state.siteParkingPassPlans[zoneUuid] || []), data.parkingPassPlan],
          }
        : state.siteParkingPassPlans,
    }),
  },
  updateParkingPassPlan: {
    action: updateSiteParkingPassPlan,
    done: (state, { params: { zoneUuid, planId }, result: { success, data } = {} }) => ({
      ...state,
      siteParkingPassPlans: success
        ? {
            ...state.siteParkingPassPlans,
            [zoneUuid]: state.siteParkingPassPlans[zoneUuid].map((plan) =>
              plan.planId === planId ? data.parkingPassPlan : plan,
            ),
          }
        : state.siteParkingPassPlans,
    }),
  },
  deleteParkingPassPlan: {
    action: deleteSiteParkingPassPlan,
    done: (state, { params: { zoneUuid, planId }, result: { success } = {} }) => ({
      ...state,
      siteParkingPassPlans: success
        ? {
            ...state.siteParkingPassPlans,
            [zoneUuid]: state.siteParkingPassPlans[zoneUuid].filter(
              (plan) => plan.planId !== planId,
            ),
          }
        : state.siteParkingPassPlans,
    }),
  },
  // #end Parking Pass Plans
};

export default applyReducers({ store, reducers });
